




import { defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  name: "Avatar",
  props: {
    size: { type: String, default: "is-48x48" },
    url: String,
    grup: String,
    gender: String,
  },
  setup(props) {
    const imageUrl = ref(props.url);
    const updateImageUrl = () => {
        if (!imageUrl.value) {
        imageUrl.value = require('@/apps/core/assets/img/avatar/profile_default.png');
        // let imageFile = "";
        // if (props.gender && ["Pria", "Wanita"].includes(props.gender)) {
        //   imageFile = props.gender === "Pria" ? "pria" : "wanita";
        // } else {
        //   imageFile = "pria";
        // }
        // if (props.grup === "Pengelola") {
        //   imageFile = `${imageFile}`;
        //   // Akan diganti menggunakan image dari AWS....
        //   imageUrl.value = require(`@/apps/core/assets/img/avatar/${imageFile}.png`);
        // } else {
        //   // const randInt = Math.floor(Math.random() * 4) + 1; // TODO: Jangan di-hardcode 30
        //   // Akan diganti menggunakan image dari AWS....
        //   imageUrl.value = require(`@/apps/core/assets/img/avatar/${imageFile}.png`);
        // }
      }
    }
    updateImageUrl();

    // watch ketika props.value berubah (selesai loading fetch)
    watch(
      () => props.url,
      (newValue) => {
        if (imageUrl.value !== newValue) {
          // Catatan: Harus pakai trick ini, di-set undefined (dan ada v-if)
          // kemudian delay dan di-set src url-nya, agar perubahan bisa
          // ter-render.
          imageUrl.value = undefined;
          setTimeout(() => (imageUrl.value = newValue), 100);
        }
      }
    );

    watch(
      () => props.gender,
      () => {
        if (!props.url) {
          // Catatan: Harus pakai trick ini, di-set undefined (dan ada v-if)
          // kemudian delay dan di-set src url-nya, agar perubahan bisa
          // ter-render.
          imageUrl.value = undefined;
          setTimeout(() => updateImageUrl(), 100);
        }
      }
    );

    return { imageUrl };
  },
});
